import './src/styles/global.css'

import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { navigate } from 'gatsby'
import type { GatsbyBrowser } from 'gatsby'
import { ThemeProvider } from "./src/context/ThemeContext"
// import { Router } from '@gatsbyjs/reach-router'

interface AppState {
  returnTo?: string
}

const onRedirectCallback = (appState: AppState | undefined): void => {
  const destination: string = appState?.returnTo ?? '/'
  void navigate(destination, { replace: true })
}

// export const wrapPageElement = ({ element }) => {
//     return <Router>{element}</Router>
// }

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}) => {
  return (
    <Auth0Provider
      domain="sudburychessclub.uk.auth0.com"
      clientId="lRgVHFLTqseGEft5VjYB9ZfvVMBBR2QO"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </Auth0Provider>
  )
}
