import React, { createContext, useState, useEffect, ReactNode } from 'react'

export interface ThemeContextProps {
  theme: 'light' | 'dark'
  toggleTheme: () => void
}

const defaultState: ThemeContextProps = {
  theme: 'light',
  toggleTheme: () => {}
}

export const ThemeContext = createContext<ThemeContextProps>(defaultState)

const getInitialTheme = () => {
  const storedTheme = localStorage.getItem('theme') as 'light' | 'dark' | null
  const initialTheme = storedTheme || 'light'
  document.documentElement.classList.add(initialTheme)
  return initialTheme
}

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<'light' | 'dark'>(getInitialTheme())
  const [isClient, setIsClient] = useState<boolean>(false)

  useEffect(() => {
    setIsClient(true)
    localStorage.setItem('theme', theme)
    document.documentElement.classList.remove('light')
    document.documentElement.classList.remove('dark')
    document.documentElement.classList.add(theme)
  }, [theme])

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
  }

  if (!isClient) {
    return null
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
